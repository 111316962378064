<template>
  <div class="create-group">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <div class="create-group-content page-layout">
      <LoadingCard v-if="isLoading" />

      <md-card
        v-if="!isLoading"
        class="md-layout md-alignment-center-center create-group__form"
      >
        <PageHeader
          :has-back="true"
          :title="$t('create-group.title')"
          @back="goBack"
        />
        <md-card-content class="md-layout-item">
          <div class="meep-form-one-column">
            <div class="meep-input">
              <!-- Nom du groupe -->
              <md-field :class="{ 'md-invalid': errors.has('name') }">
                <label> {{ $t("create-group.name") }} *</label>
                <md-input
                  v-model="groupe.name"
                  v-validate="'required'"
                  name="name"
                  type="text"
                >
                </md-input>
                <span v-show="errors.has('name')" class="md-error">
                  {{ errors.first("name") }}
                </span>
              </md-field>
            </div>

            <h3 class="create-group__block-title">
              {{ $t("create-group.linked-companies") }}
            </h3>

            <div class="meep-input meep-select">
              <md-field>
                <label class="meep-select-label">
                  {{ $t("create-group.link-company") }}
                </label>
                <md-select
                  id="companies"
                  v-model="groupe.companies"
                  multiple
                  name="companies"
                >
                  <md-option
                    v-for="company in companies"
                    :key="company.id"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
            <md-list class="meep-select-list">
              <md-list-item
                v-for="companyId in groupe.companies"
                :key="companyId"
              >
                <div class="md-list-item-text create-group__list-item">
                  {{ getCompanyName(companyId) }}
                </div>

                <md-button
                  class="md-icon-button md-list-action"
                  @click="removeCompany(companyId)"
                >
                  <md-icon class="md-primary"> cancel</md-icon>
                </md-button>
              </md-list-item>
            </md-list>
            <div class="md-layout md-alignment-center-right">
              <md-button
                class="md-raised md-primary"
                @click.native="createGroup('create')"
              >
                {{ $t("create-group.create") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <confirm-action-modal
      v-if="isConfirmModalOpen"
      :object-to-act-upon="{}"
      :text="confirmModalText"
      @close="isConfirmModalOpen = false"
      @confirm="onConfirmModal"
    />
  </div>
</template>

<script>
import groupesModel from "../../model/groupes";
import companiesModel from "../../model/companies";
import PageHeader from "@/components/PageHeader";
import LoadingCard from "@/components/LoadingCard";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";
import ConfirmActionModal from "../../components/modal/confirm-action";
import ForceLeaveMixin from "@/mixins/ForceLeaveMixin";
import { compareObjects } from "@/services/util";

export default {
  name: "CreateGroup",

  mixins: [ForceLeaveMixin],

  components: {
    LoadingCard,
    PageHeader,
    Banner,
    MeepIconSettings,
    ConfirmActionModal,
  },

  data() {
    return {
      groupe: {
        name: "",
        companies: [],
      },
      oldGroupe: null,
      isConfirmModalOpen: false,
      isCreateGroup: false,
      companies: [],
      isLoading: false,
    };
  },

  computed: {
    confirmModalText() {
      if (this.isCreateGroup) {
        return {
          header: this.$t("groupes.create-modal.title"),
          body: () => {
            return (
              this.$t("groupes.create-modal.body") + ` ${this.groupe.name}`
            );
          },

          question: this.$t("groupes.modal.question"),
        };
      } else {
        return {
          header: this.$t("groupes.create-modal.title"),
          body: () => {
            return this.$t("groupes.create-modal.leave");
          },
          question: this.$t("groupes.create-modal.question"),
        };
      }
    },

    isAnyModified() {
      return !compareObjects(this.groupe, this.oldGroupe);
    },
  },

  async mounted() {
    this.isLoading = true;
    this.oldGroupe = { ...this.groupe };
    this.companies = await companiesModel.getAll();
    this.isLoading = false;
  },

  methods: {
    goBack() {
      window.history.back();
      this.isCreateGroup = false;
    },

    removeCompany(companyId) {
      this.groupe.companies = this.groupe.companies.filter(
        company => company !== companyId
      );
    },

    getCompanyName(companyId) {
      const company = this.companies.find(company => company.id === companyId);

      return company ? company.name : "";
    },

    // confirm create group
    async confirmCreateGroup() {
      try {
        await groupesModel.create(this.groupe);
        this.nextPath = "/dashboard/setting/groupes/";
        this.forceRouteLeave();
        this.$toasted.global.AppSucces({
          message: "Le groupe " + this.groupe.name + " a bien été créé",
        });
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    onConfirmModal() {
      if (this.isCreateGroup) {
        this.confirmCreateGroup();
      } else {
        this.forceRouteLeave();
      }
    },

    async createGroup(text) {
      if (text === "create") {
        const result = await this.$validator.validateAll();
        if (result) {
          this.isCreateGroup = true;
          this.openConfirmModal();
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      }
    },
    openConfirmModal() {
      this.isConfirmModalOpen = true;
    },

    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .create-group {
    &__form {
      width: 700px;
    }

    &__list-item {
      font-weight: bold;
    }

    &__block-title {
      margin-bottom: 0;
      font-family: var(--font);
      margin: 1rem 0;
      font-size: toRem(14);
      @include for-lg {
        font-size: toRem(20);
      }
    }

    .md-layout-item .md-list {
      height: fit-content;
    }

    &-content {
      .md-card {
        &-content {
          .md-layout {
            .md-button {
              padding: 0 35px;
            }
          }
        }
      }
    }

    .meep-select {
      .md-field {
        margin: 4px 0 10px;

        label {
          font-size: 17px;
        }

        &.md-has-value {
          label {
            font-size: 12px;
          }
        }
      }
    }

    .md-card {
      border-radius: 20px;
      padding: 50px 40px;

      &-content {
        padding: 0;

        .meep-form-one-column .meep-input {
          .md-field {
            .md-input {
              height: 47px;
              min-height: unset;
              margin: 0;
              @include for-lg {
                height: 50px;
              }
            }

            label {
              top: 20px;
              left: 12px;
              font-size: toRem(12);
              @include for-lg {
                left: 19px;
                font-size: toRem(17);
              }
            }

            &.md-has-value,
            &.md-focused {
              margin: 0;

              label {
                font-size: toRem(12);
                transform: translateY(-8px);
              }
            }
          }
        }

        .md-alignment-center-right {
          .md-button {
            font-size: toRem(9);
            @include for-lg {
              font-size: toRem(14);
            }

            .md-ripple {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
</style>
