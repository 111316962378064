import { MODALS } from "@/constants";

const ForceLeaveMixin = {
  data() {
    return {
      forceLeave: false,
      nextPath: "",
    };
  },

  methods: {
    leave() {
      this.$router.push(this.nextPath);
    },

    forceRouteLeave() {
      this.forceLeave = true;
      this.$router.push(this.nextPath);
    },
  },

  beforeRouteLeave(to, _, next) {
    if (this.forceLeave) {
      next();
    }

    if (!this.forceLeave) {
      if (this.isAnyModified) {
        this.confirmationContext = MODALS.UNSAVE;
        this.nextPath = to.fullPath;
        this.openConfirmModal();
        next(false);
      } else {
        next();
      }
    }
  },
};

export default ForceLeaveMixin;
